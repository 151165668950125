<template>
  <b-modal
    modal
    scrollable
    title="Create NCR Realtor"
    v-model="isVisible"
    size="lg"
    modal-class="modal-primary"
    @hidden="openMe(false)"
    title-tag="h3"
    :no-close-on-backdrop="true"
  >
    <ValidationObserver ref="form">
      <!-- Account -->
      <b-row>
        <b-col cols="6">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group
              :label="isClientTakenFromStore ? '' : 'Search by account'"
              :style="{ marginTop: isClientTakenFromStore ? '5px' : '0px' }"
            >
              <b-input-group class="mr-1">
                <b-form-input
                  :disabled="isClientTakenFromStore"
                  placeholder="Account"
                  v-model="client.account"
                  :class="{ 'border-danger': errors[0] }"
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="searchClient"
                    :disabled="!client.account || realtorIsBeenPaid"
                    v-if="!isClientTakenFromStore"
                  >
                    <span
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    <span v-else class="visually-hidden">Search</span>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col cols="6">
          <h5
            class="text-left"
            :style="{ marginTop: isClientTakenFromStore ? '0px' : '4px' }"
          >
            {{ isClientTakenFromStore ? "" : "Client" }}
          </h5>
          <p
            class="rounded text-primary border-primary font-medium-1 text-center py10"
          >
            {{
              hasClient
                ? client.client_name
                  ? client.client_name
                  : client.name
                : " - "
            }}
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-0 rounded">
        <b-col cols="9">
          <div class="d-flex justify-content-start">
            <p class="px-3 paddingY bg-primary bordeL text-white fontW my-0">
              AMOUNT
            </p>
            <b-form-group
              v-slot="{ ariaDescribedby }"
              :class="
                ((client.option == '' && stateOthers == false) ||
                  (stateOthers && client.others == '')) &&
                onSaveAmount
                  ? 'border-danger'
                  : 'border-light'
              "
              class="w-100 m-0"
              required
            >
              <div
                class="d-flex justify-content-around my-0 border bordeR paddingY"
              >
                <b-form-radio
                  v-model="client.option"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="0.00"
                  @change="others(false, '0.00')"
                  >$ 0.00</b-form-radio
                >
                <b-form-radio
                  v-model="client.option"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  value="49.99"
                  @change="others(false, '49.99')"
                  >$ 49.99</b-form-radio
                >
                <b-form-radio
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  :value="client.others"
                  @change="others(true, null)"
                  >Others
                </b-form-radio>
              </div>
            </b-form-group>
          </div>
        </b-col>
        <b-col v-if="stateOthers" cols="3" class="d-flex justify-content-end">
          <ValidationProvider v-slot="{ errors }" name="AMOUNT" rules="money-2">
            <b-input-group prepend="$" class="inputOthers">
              <money
                v-model="client.others"
                class="form-control"
                v-bind="vMoney"
                :style="{
                  borderColor:
                    errors[0] === 'The amount must be greater than 0.00' &&
                    validador
                      ? '#fc424a'
                      : isDarkSkin
                      ? '#d8d6de'
                      : 'rgb(217 209 209)',
                }"
              />
            </b-input-group>
            <label v-if="errors[0]" class="text-danger">
              {{ errors[0] }}
            </label>
          </ValidationProvider>
        </b-col>
      </b-row>
      <!-- Method Payment -->
      <div
        class="w-100 d-flex flex-column justify-content-center"
        v-if="amountValidate"
      >
        <b-row class="justify-content-start pl-2 mt-1">
          <b-form-group label="Method Payments">
            <b-form-radio-group
              v-model="methodPaymentSelected"
              :options="optionsMethodsPayment"
            />
          </b-form-group>
        </b-row>
        <b-row
          class="justify-content-start pl-2"
          v-if="methodPaymentSelected != 3 && methodPaymentSelected"
        >
          <b-form-group label="Type Payment">
            <b-form-radio-group
              v-model="typePaymentSelected"
              :options="optionsTypePayment"
            />
          </b-form-group>
          <ValidationProvider
            rules="money-2"
            v-slot="{ errors }"
            name="AMOUNT_PARTIAL"
          >
            <b-form-group
              v-if="methodPaymentSelected == 2"
              class="mt-1 ml-1 mb-0"
            >
              <b-input-group prepend="$" class="inputOthers">
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >AMOUNT PARTIAL
                  </b-input-group-text>
                </template>
                <money
                  v-bind="vMoney"
                  v-model="amountPartial"
                  class="form-control"
                  :style="{
                    borderColor:
                      errors[0] === 'The amount must be greater than 0.00' &&
                      validador
                        ? '#fc424a'
                        : isDarkSkin
                        ? '#d8d6de'
                        : 'rgb(217 209 209)',
                    opacity: isDarkSkin
                      ? errors[0] === 'The amount must be greater than 0.00' &&
                        validador
                        ? '1'
                        : '0.3'
                      : '1',
                  }"
                />
              </b-input-group>
            </b-form-group>
            <label
              v-if="methodPaymentSelected == 2 && errors[0]"
              class="text-danger ml-1 mt-0"
            >
              {{ errors[0] }}
            </label>
          </ValidationProvider>
        </b-row>
        <b-row
          v-if="methodPaymentSelected != 3 && typePaymentSelected"
          class="justify-content-around"
        >
          <b-col v-if="typePaymentSelected == 1">
            <b-row>
              <b-col>
                <b-table
                  :style="[
                    'fontSize:5px',
                    'fontWeight:thin',
                    'overflow-y: scroll',
                  ]"
                  small
                  :items="cards"
                  :fields="fieldsCards"
                  class="w-100 mb-0"
                  sticky-header
                >
                  <template #cell(select)="data">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-radio
                        v-model="cardSelected"
                        :value="data.item.id"
                        @change="assignIndexSelected(data.index)"
                        :class="errors[0] && validCard ? 'border-danger' : ''"
                      />
                    </ValidationProvider>
                  </template>
                  <template #cell(cardnumber)="data">
                    <center>xxxx-xxxx-xxxx-{{ data.item.cardnumber }}</center>
                  </template>
                  <template #cell(cardsecuritycode)>
                    <center>xxx</center>
                  </template>
                  <template #cell(created_by)="data">
                    <div class="text-center">
                      <b-container>
                        <b-row>{{ data.item.created_by }}</b-row>
                        <b-row>{{
                          data.item.created_at | myGlobalWithHour
                        }}</b-row>
                      </b-container>
                    </div>
                  </template>
                  <template #cell(action)="data">
                    <div class="d-flex justify-content-center">
                      <feather-icon
                        icon="Trash2Icon"
                        class="text-danger cursor-pointer"
                        size="15"
                        @click="openModalDeleteCard(data.item.id)"
                      />
                    </div>
                  </template>
                </b-table>
                <span
                  v-if="!hasCardSelected && validCard"
                  class="text-danger mt-0 ml-1"
                >
                  Card is required
                </span>
              </b-col>
            </b-row>
          </b-col>
          <div v-if="typePaymentSelected == 2" class="w-100 px-2">
            <b-row class="justify-content-">
              <b-col :cols="6">
                <b-form-group label="Type">
                  <b-select
                    v-model="typeSelect"
                    :options="optionsType"
                    value-field="id"
                    label="text"
                  />
                </b-form-group>
              </b-col>
              <b-col :cols="6">
                <b-form-group label="Serial Number">
                  <b-form-input class="text-center" v-model="serialNumber" />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-row>
        <div class="d-flex flex-row-reverse" v-if="typePaymentSelected == 1">
          <b-button variant="primary" @click="openModalCardCreate()">
            <feather-icon icon="PlusIcon" size="16" />
            Add Card
          </b-button>
        </div>
      </div>
    </ValidationObserver>
    <template #modal-footer>
      <div class="d-flex justify-content-center">
        <b-button
          v-if="typePaymentSelected == 1 || methodPaymentSelected == 3"
          variant="success"
          @click="saveNcrRealtor"
          size="lg"
        >
          Save Charge
        </b-button>
      </div>
    </template>
    <!-- Modal Approve -->
    <modal-approve-supervisor
      v-if="ModalApproveSupervisor"
      :modalApproveSupervisor="ModalApproveSupervisor"
      @closeModalApprove="closeModalApprove"
      @approveSup="approveSup"
    />
    <modal-card-create
      v-if="isActiveModalCreateCard"
      @click="closeModalCardCreate"
      @new="refresCardTable"
      :ifModalCard="isActiveModalCreateCard"
      :idlead="lead_id"
      :session="currentUser.user_id"
    />
    <delete-card
      :card_id="cardSelected"
      :lead_id="lead_id"
      :ifModalCard="isActiveModalDeleteCard"
      :session="currentUser.user_id"
      @click="closeModalDeleteCard()"
      @new="refresCardTable"
    />
  </b-modal>
</template>
<script>
// store
import { mapGetters, mapMutations } from "vuex";
//components
import ModalApproveSupervisor from "@/views/commons/components/clients/dashboard/information-client/modals/ModalApproveSupervisor.vue";
import ModalCardCreate from "@/views/crm/views/payments/components/ModalCardCreate.vue";
import DeleteCard from "@/views/crm/views/sales-made/components/modals/DeleteCardModal.vue";
//services
import NcrRealtorService from "@/views/commons/components/ncr-realtor/services/ncr-realtor.service";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import cardsFields from "@/views/commons/components/ncr-realtor/views/data/fields-cards.fields";
import FinancialCommissionsService from "@/views/ce-digital/sub-modules/financial/view/commissions/financial-commissions.service";

export default {
  components: {
    ModalApproveSupervisor,
    ModalCardCreate,
    DeleteCard,
  },
  data() {
    return {
      cardData: [],
      cardSelected: null,
      amountPartial: 0,
      fieldsCards: cardsFields,
      isVisible: false,
      client: {
        account: "",
        name: "",
        card: 0,
        obs: "",
        option: "",
        typencr: null,
        others: "",
      },
      stateOthers: false,
      hasClient: false,
      indexCardSelected: null,
      isLoading: false,
      lead_id: 0,
      isActiveModalCreateCard: false,
      isActiveModalDeleteCard: false,
      realtorIsBeenPaid: false,
      vMoney: {
        decimal: ".",
        thousands: ",",
        precision: 2,
        masked: false,
        prefix: "$ ",
        min: 0.0,
      },
      methodPaymentSelected: null,
      onSaveAmount: false,
      optionsMethodsPayment: [
        { text: "Direct Payment", value: 1 },
        { text: "Parcial", value: 2 },
        { text: "Fee Charge", value: 3 },
      ],
      optionsTypePayment: [
        { text: "Credit Card", value: 1 } /*,{ text:'Others',value:2 } */,
      ],
      optionsType: [
        { id: 1, text: "Cash" },
        { id: 2, text: "Check" },
        { id: 3, text: "Money Order" },
        { id: 4, text: "Cashier Check " },
      ],
      serialNumber: "",
      showCards: false,
      typeSelect: 0,
      ModalApproveSupervisor: false,
      typePaymentSelected: null,
      cards: [],
      validador: true,
      validCard: false,
      validAmount: false,
      amountValidate: false,
    };
  },
  created() {
    this.openMe(true);
  },
  async mounted() {
    if (this.isClientTakenFromStore) {
      // Client data could be taken from the store when this modal is called from the dashboard
      this.client.account = this.clientFromStore.account;
      await this.searchClient();
      this.client.option = "0.00";
      this.methodPaymentSelected = 3;
    }
  },
  methods: {
    others(value, amount) {
      this.stateOthers = value;
      this.onSaveAmount = false;
      this.client.option = amount;

      if (amount == "0.00") {
        this.amountValidate = false;
        this.methodPaymentSelected = 3;
      } else {
        this.amountValidate = true;
        this.methodPaymentSelected = null;
      }
    },
    cardId(data) {
      this.client.card = data.id;
      this.client.typencr = data.type_card;
    },
    closeModalApprove() {
      this.ModalApproveSupervisor = false;
    },
    //funcion que activa o desactiva el modal
    openMe(open = true) {
      if (open) {
        this.isVisible = open;
      } else {
        this.isVisible = open;
        this.$emit("closeMe");
      }
    },
    //funcion que trae los datos del cliente buscado
    async searchClient() {
      const parameters = {
        account: this.client.account,
        session: this.currentUser.user_id,
        program: this.programId,
        role: this.currentUser.role_id,
      };
      this.isLoading = true;
      const client = await NcrRealtorService.searchClient({ ...parameters });
      this.isLoading = false;
      if (client) {
        this.hasClient = true;
        this.client.name = client.name_client;
        this.client.cards = client.cards;
        this.cards = client.cards;
        this.showCards = true;
        this.client.id = client.client_account_id;
        this.lead_id = client.id_lead;
      } else {
        this.hasClient = false;
        this.showErrorSwal("Enter a valid account", "No records found");
      }
    },
    saveNcrRealtor: async function () {
      const response = await this.saveCharge();
      if (!response) return;
      try {
        this.addPreloader();
        const params = {
          id_account: this.client.id,
          amount: this.client.option || this.client.others,
          obs: this.client.obs,
          session: this.currentUser.user_id,
          card: this.client.option == 0 ? null : this.client.card,
          module: this.moduleId,
          typencr: 1,
        };
        const data = await NcrRealtorService.createNcrRealtor(params);
        this.removePreloader();
        if (data.status === 200) {
          if (data.data.code == 1 || data.data.code == 500) {
            this.showToast(
              "danger",
              "top-right",
              data.data.message.toUpperCase(),
              "XIcon",
              "It couldn't be created"
            );
          } else {
            if (this.moduleId === 22) {
              await FinancialCommissionsService.createCommissionChargesCed({
                chargeId: 18,
                base_amount: this.client.option || this.client.others,
                agent_id: this.currentUser.user_id,
                client_account: this.client.id,
                module_id: this.moduleId,
              });
            }
            if (this.isClientTakenFromStore) this.setPaymentsTableStatus(true);
            this.showSuccessSwal("Success!", "OPERATION SUCCESSFULLY");
            this.$emit("refreshTable");
            this.openMe(false);
          }
        } else {
          this.showErrorSwal();
        }
      } catch (error) {
        this.removePreloader();
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
      }
    },
    approveSup: async function () {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          const params = {
            id_account: this.client.id,
            amount: this.client.option || this.client.others,
            obs: this.client.obs,
            session: this.currentUser.user_id,
            card: this.option == 0 ? null : this.client.card,
            module: this.moduleId,
          };
          this.addPreloader();
          const response = await NcrRealtorService.createNcrRealtor(params);
          this.removePreloader();
          if (response.status === 200) {
            if (response.data.code == 1 || response.data.code == 500) {
              this.showToast(
                "danger",
                "top-right",
                response.data.message.toUpperCase(),
                "XIcon",
                "It couldn't be created"
              );
            } else {
              this.showSuccessSwal("Success!", "OPERATION SUCCESSFULLY");
              this.$emit("refreshTable");
              this.openMe(false);
            }
          }
        } catch (error) {
          this.removePreloader();
          console.log(error);
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Something went wrong with Create NCR!"
          );
        }
      }
    },
    openModalCardCreate() {
      this.isActiveModalCreateCard = true;
    },
    closeModalCardCreate(visible = false) {
      this.isActiveModalCreateCard = visible;
    },
    refresCardTable(data) {
      this.assignCardData(data);
    },
    assignCardData(arr) {
      this.cards != [] ? (this.cards = []) : "";
      arr.map((el) => {
        el.cardnumber ? this.cards.push(el) : "";
      });
    },
    openModalDeleteCard(cardId) {
      this.cardSelected = cardId;
      this.isActiveModalDeleteCard = true;
    },
    closeModalDeleteCard(visible = false) {
      this.isActiveModalDeleteCard = visible;
    },
    async saveCharge() {
      this.validCard = true;
      if (
        this.methodPaymentSelected != 3 &&
        !this.hasCardSelected &&
        this.validCard
      )
        return false;
      this.$refs.form.validate().then(async (success) => {
        if (
          !(
            success &&
            !(
              (this.client.option == "" && this.stateOthers == false) ||
              (this.stateOthers && this.client.others == "")
            )
          )
        ) {
          this.onSaveAmount = true;
          return false;
        }
      });
      if (
        !(
          this.moduleId == 7 ||
          this.isCeo ||
          this.isSupervisor ||
          this.isTeamLeader ||
          [14, 15, 16, 17].some((item) => item == this.currentUser.role_id)
        )
      ) {
        this.ModalApproveSupervisor = true;
        return false;
      }
      if (this.methodPaymentSelected == 2 && this.amountPartial < 1)
        return false;
      if (this.stateOthers && this.client.others < 1) return false;
      let swalResponse = await this.showConfirmSwal();
      if (!swalResponse.isConfirmed) return false;
      try {
        this.addPreloader();
        const parameters = {
          id: this.client.id,
          account: this.client.account,
          concept: 18, // To check later
          ammount: this.stateOthers
            ? this.client.others.toString()
            : this.client.option.toString(),
          ammount_partial:
            this.methodPaymentSelected == 2
              ? this.amountPartial
              : this.suggest_charge,
          method_payment: this.methodPaymentSelected,
          card_id: this.cardSelected,
          type_payment: this.typePaymentSelected,
          type: this.typeSelect != 1 ? this.typeSelect : 0,
          specify: this.serialNumber,
          user_id: this.currentUser.user_id,
          notcharges: "NCR REALTOR",
          programid: this.programId,
          type_process: 0,
          modul: this.moduleId,
          merchant:
            this.typePaymentSelected == 1
              ? this.cards[this.indexCardSelected].type_card
              : null,
          idapp: null,
          nameCharge: null,
          settlementcost: null,
          idcreditor: null,
        };
        const response = await ClientsOptionsServices.saveCharge(parameters);
        this.realtorIsBeenPaid = response.data.status == 200;
        if (response.data.status != 200) {
          this.showToast(
            "danger",
            "top-right",
            response.data.transaction.errors.error[0].errorText,
            "XIcon",
            "Invalid"
          );
        }
        this.removePreloader();
        if (response.data.status == 200) return true;
        return false;
      } catch (err) {
        this.removePreloader();
        console.error(err);
      }
    },
    assignIndexSelected(value) {
      this.indexCardSelected = value;
    },
    ...mapMutations({
      // Used to update the payments table when this modal is called from the client dashboard
      setPaymentsTableStatus: "DebtSolutionClients/SET_UPDATE_PAYMENTS_TABLE",
    }),
  },
  computed: {
    programId() {
      switch (parseInt(this.moduleId)) {
        //debt solution
        case 5:
          return 4;
          break;
        //credit experts
        case 6:
          return 3;
          break;
        case 22:
          return 3;
          break;
        //specialist
        case 11:
          return 7;
          break;
        //boost credit
        case 7:
          return 2;
          break;
      }
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      clientFromStore: "DebtSolutionClients/G_CLIENTS",
    }),
    hasCardSelected() {
      return this.cardSelected ? true : false;
    },
    isClientTakenFromStore() {
      return !!this.clientFromStore.account;
    },
  },
};
</script>
<style scoped>
.paddingY {
  padding-bottom: 8px;
  padding-top: 8px;
}
.paddingX {
  padding-left: 45px;
  padding-right: 45px;
}
.marginR {
  margin-right: 10px;
}
.bordeR {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bordeL {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.fontW {
  font-weight: bold;
}
.inputOthers {
  height: 36px;
}

.title_lable {
  width: 150px;
}
</style>
